import React from "react";
import c from "classnames";

const Section = ({ children, display, bg, fg, padding, ...props }) => (
  <section className={c(bg, fg, padding)} {...props}>
    <div className="mw8 m-auto">{children}</div>
  </section>
);

Section.defaultProps = {
  display: "db",
  bg: "bg-black-80",
  fg: "white",
  padding: "ph3 pv5"
};

export default Section;
