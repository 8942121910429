import React from "react";

const Navbar = () => {
  return (
    <div className="bg-white ph3 pv3 ">
      <div className="flex justify-between items-center">
        <img className="h3" src="/img/logo.png" alt="RKH & Associates" />
        <div className="tr nr3">
          <a
            className="no-underline underline-hover gray ph3"
            href="#manufacturers"
          >
            Our Manufacturers
          </a>

          <a className="no-underline underline-hover gray ph3" href="#about">
            About Us
          </a>

          <a className="no-underline underline-hover gray ph3" href="#contact">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
