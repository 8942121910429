export default [
  {
    name: "AIRVENT",
    image: "/img/airvent.jpg",
    url: "http://www.airvent.com",
  },
  {
    name: "AMERICAN PRIDE CABINETS",
    image: "/img/apcabinets.jpg",
    url: "http://www.apcabinets.com",
  },
  {
    name: "APPLETON SUPPLY",
    image: "/img/appleton.jpg",
    url: "http://www.appletonsupply.com",
  },
  {
    name: "BRADLEY PLASTIC LOCKERS",
    image: "/img/bradley.jpg",
    url: "https://www.bradleycorp.com/product-category/plastic-lockers",
  },
  {
    name: "BRADLEY TOILET PARTITIONS",
    image: "/img/bradley.jpg",
    url: "https://www.bradleycorp.com/product-category/toilet-partitions",
  },
  {
    name: "BRADLEY WASHROOM ACCESSORIES",
    image: "/img/bradley.jpg",
    url: "https://www.bradleycorp.com/product-category/washroom-accessories",
  },
  {
    name: "COASTAL SHOWER DOORS",
    image: "/img/coastal.png",
    url: "https://www.coastalshowerdoors.com",
  },
  {
    name: "GILDED MIRROR",
    image: "/img/gildedmirror.png",
    url: "http://www.gildedmirrorsinc.com",
  },
  {
    name: "HOMEGUARD",
    image: "/img/homeguard.jpg",
    url: "https://www.oxengineeredproducts.com/product/homeguard/",
  },
  {
    name: "PHILADELPHIA HARDWARE GROUP",
    image: "/img/phg.jpg",
    url: "https://philadelphiahardware.com/products/",
  },
  {
    name: "NOVAGARD",
    image: "/img/novagard.jpg",
    url: "https://novagard.com/products/construction-silicones-hybrid-adhesives/",
  },
  {
    name: "OX",
    image: "/img/ox.jpg",
    url: "http://www.oxengineeredproducts.com",
  },
  {
    name: "SAUDER CLOSETS",
    image: "/img/woodtrac.png",
    url: "https://www.woodtrac.com/Closet/Closets.aspx",
  },
  {
    name: "SAUDER KITCHEN CABINETS",
    image: "./img/saudercabinetry.png",
    url: "https://saudercabinetry.com/cabinets",
  },
  {
    name: "TRUE FLOW GUTTER GUARDS",
    image: "/img/trueflow.webp",
    url: "https://www.gutterguardstrueflow.com",
  },
];
