import React from "react";

const Manufacturer = ({ color, img, name, url }) => {
  return (
    <a
      className="dib ma3 w5 v-top pointer no-underline"
      href={url}
      target="_tab"
    >
      <div
        className={`br-100 bg-black-10 hover-bg-${color} w4 h4 relative m-auto`}
      >
        <div className="bg-white br-100 absolute absolute--fill flex ma2">
          <img className="m-auto w3" src={img} alt={name} />
        </div>
      </div>
      <span className={`db bb b--${color} bw1 tc pa3 fw7 mw4 m-auto black-80`}>
        {name}
      </span>
    </a>
  );
};

export default Manufacturer;
