import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import Navbar from "./components/Navbar";
import Manufacturer from "./components/Manufacturer";
import Section from "./components/Section";
import manufacturers from "./manufacturers";
import "./css/tachyons.css";
import "./css/app.css";

const colors = ["red", "green", "blue", "yellow"];

const App = () => {
  return (
    <div>
      <Navbar />
      <div
        className="relative vh-100 flex ph3"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("/img/bg.jpg") no-repeat fixed center`
        }}
      >
        <div className="m-auto">
          <h1 className="white f1 tc mb5">
            QUALITY PRODUCTS FOR THE BUILDING INDUSTRY
          </h1>

          <div className="tc">
            <a
              href="#manufacturers"
              className="pointer dib bg-red hover-bg-dark-red white bn br1 ph4 pv3 mh3 no-underline"
            >
              MANUFACTURERS
            </a>
            <a
              href="#contact"
              className="pointer dib bg-green hover-bg-dark-green white bn br1 ph4 pv3 mh3 no-underline"
            >
              CONTACT US
            </a>
          </div>
        </div>
      </div>

      <Section bg="bg-white" fg="black">
        <h2 id="manufacturers" className="f2 tc mt0 mb4">
          OUR MANUFACTURERS
        </h2>
        <p className="tc mb4">
          Providing top-of-the-line home-improvement products and building
          hardware{" "}
        </p>

        <div className="tc pv4">
          {manufacturers.map(({ name, image, url }, i) => (
            <Manufacturer
              key={name}
              name={name}
              img={image}
              url={url}
              color={colors[i % colors.length]}
            />
          ))}
        </div>
      </Section>

      <Section>
        <h2 id="about" className="fw7 f2 tc mt0 mb4">
          ABOUT
        </h2>

        <p className="lh-copy f3 white-70 ma0">
          Formed in 2006, RKH & Associates, Inc., of Columbus, Ohio, is a
          manufacturers’ representative company (contractual agents) focusing on
          products related to residential and commercial building. With decades
          of experience, we are your conduit for information about this industry
          and know most of the solutions for common problems. We also believe in
          becoming successful by taking the time to educate you and your
          customers.
        </p>
      </Section>

      <Section id="contact" bg="bg-red">
        <h2 className="fw7 f2 tc mt0 mb4">CONTACT US</h2>

        <p className="f3 lh-copy ma0 tc">
          Contact us at{" "}
          <a
            className="white no-underline underline-hover"
            href="tel:+16142064797"
          >
            (614) 206-4797
          </a>{" "}
          or{" "}
          <a
            className="white no-underline underline-hover"
            href="mailto:info@rkhassociates.com"
          >
            info@rkhassociates.com
          </a>{" "}
          for further details about purchasing top-rated construction products.
        </p>
      </Section>
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
